import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-79d4f602"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "common-question"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "index"
};
const _hoisted_4 = {
  class: "desc"
};
import { onMounted, ref } from 'vue';
import { getCommonContentList } from '../../../api/common';
export default {
  __name: 'index',
  setup(__props) {
    const commonContentList = ref([]);
    // 处理数据
    const handleData = data => {
      return data;
    };
    onMounted(() => {
      const data = {
        status: 1,
        type: 'problem'
      };
      getCommonContentList(data).then(res => {
        const response = res;
        commonContentList.value = handleData(response);
      });
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(commonContentList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: index
        }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(index + 1), 1)]), _createElementVNode("span", null, _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_4, _toDisplayString(item.desc), 1)]);
      }), 128))]);
    };
  }
};