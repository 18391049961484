import { api } from '../utils/api';
/** 获取常见问题列表  */
export async function getCommonContentList(data) {
    return api('/web/common/content/all', data, 'POST', false);
}

// 首页轮播图列表
export async function getBannerList(data) {
    return api('/web/common/content/all', data, 'POST', false, false, false);
}

// 浏览记录添加
export async function historyAdd(data) {
    return api('/web/common/history/add', data, 'POST', true, false, false);
}

// 浏览pv添加
export async function pvAdd(data) {
    return api('/web/common/pv/add', data, 'POST', true, false, false);
}